.frame1-container{
    width: 176px;
    position: relative;
    padding: 10px 5px;
}
.lists{
    cursor : pointer;
    width: 100%;
    margin-left: -10px;
    color: #a0a0a0;
    font-size: 11pt;
}
a {
    text-decoration: none; /* 밑줄 제거 */
    color: inherit; /* 링크 색상을 부모 요소에서 상속 */
  }
.Frame1{
    cursor : pointer;
    width: 100%;
}