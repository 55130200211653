.home-main{
    font-family: 'IBMPlexSansKR-Regular';
    display: inline-flex;
    width: 100%;
}
.login-header{
    margin: 10px 40px;
    display: flex;
    position: fixed;
    z-index: 2;
}
.logoimg{
    max-width: 50px;
    margin-right: 10px;
    cursor : pointer;
}
.univcertlogo{
    max-width: 150px;
    cursor : pointer;
}

.advertisement-main {
    position: fixed;
    top: 0;
    left: 0;
    width: 40%;
    height: 100vh;
    background: #f3f6fc;
    z-index: 1;
    font-family: 'NanumSquare';
    display: flex;
    justify-content: center;
    align-items: center;
}

.advertisement-image {
    width: 80%;
    max-width: 500px;
}

.signup-main {
    position:fixed; 
    top:0px;
    right:0px; 
    width: 60%;
    min-width: 480px;
    height:100vh; 
    background:#ffffff; 
    z-index: 1;
    font-family: 'NanumSquare';
    display: flex;
    justify-content: center;
    align-items: center;
}
.body-outer{
    min-width: 500px;
    width: 40%;
}
.Body_body__KI0OY {
    margin: 0 auto;
    align-items: center;
    width: 80%;
}
.TextPanel_className__J3J2W {
    text-align: center;
    word-break: break-word;
    margin-top: 0px;
    margin-bottom: 40px;
}
.TextPanel_className__J3J2W h1 {
    line-height: 5.0;
    font-size: 20pt;
    font-weight: 600;
    color: #045fc7;
    margin-top: -5px;
}
.InputPanel_wrapper__RXp1k {
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.InputPanel_password__my_BO {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
    z-index: -1;
    top: -10px;
    left: 0;
    padding: 0;
    height: 0;
    border: 1px solid transparent;
}
.InputPanel {
    padding-bottom: 44px;
}
.style_wrapper__6RiUK {
    position: relative;
    padding-bottom: 22px;
    color: #767676;
}
.style_label__BKYHB {
    font-size: 14px;
    font-weight: 700;
}
.style_body__A6XnO {
    margin-top: 11px;
    margin-right: 20px;
}
.style_body__A6XnO input[type=email], .style_body__A6XnO input[type=password], .style_body__A6XnO input[type=text] {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35px;
    padding-left: 15px;
    margin-top: -8px;
    border-radius: 5px;
    border: 1px solid #e1e2e3;
    background-color: #fff;
    font-size: 15px;
    color: #333;
    box-shadow: none;
    outline: none;
    resize: none;
    font-family: 'NanumSquare';
    font-size: 10pt;
    font-weight: 500;
}
.style_guidance__FT8Qs {
    color: #767676;
}
.style_error__PioA9, .style_guidance__FT8Qs {
    bottom: 0;
    margin-top: 6px;
    font-size: 12px;
}
.join-complete{
    display: flex;
    justify-content: center;
    margin-top: -8px;
}

.login-button {
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    border: 0;
    border-radius: 6px;
    background-color: #739BE7;
    font-size: 11pt;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    font-family: 'GmarketSansMedium';
}
.login-button {
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    border: 0;
    border-radius: 6px;
    background-color: #739BE7;
    font-size: 11pt;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    font-family: 'GmarketSansMedium';
}
.create-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    border: 1px solid #8b8b8b;
    border-radius: 6px;
    background-color: #f1f4f9;
    font-size: 11pt;
    font-weight: 500;
    color: #8b8b8b;
    cursor: pointer;
    font-family: 'GmarketSansMedium';
}


.style_wrapper__IgK7U svg {
    margin-right: 10px;
}
.InputPanel_divider__WEgZ3:after {
    display: block;
    content: "or";
    color: #969696;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    margin: 13px auto;
    white-space: pre;
}
.InputPanel_socialLogins__j0wq7 {
    text-align: center;
    color: #999;
    font-size: 8pt;
    margin-bottom: 15px;
}
.InputPanel_socialWrapper__Dhaxo.isKR {
    width: 100%;
}
.InputPanel_socialWrapper__Dhaxo {
    width: calc(100% - 60px);
    height: 84px;
    margin: auto;
}
.InputPanel_socialWrapper__Dhaxo .InputPanel_socialButton__Hggw2.isKR {
    width: 25%;
}
.InputPanel_socialWrapper__Dhaxo .InputPanel_socialButton__Hggw2 {
    width: 33.33333333%;
    display: inline-block;
    position: relative;
}
.InputPanel_wrapper__RXp1k .social-login-button.kakao-login {
    background-color: #fee500;
}
.InputPanel_wrapper__RXp1k .social-login-button {
    width: 56px;
    height: 56px;
    margin: 0 auto 8px;
    color: #737373;
    border-radius: 28px;
}
button {
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
}
.InputPanel_wrapper__RXp1k .social-login-button svg {
    margin-right: 0;
}
.InputPanel_socialWrapper__Dhaxo .InputPanel_socialButton__Hggw2 .InputPanel_socialTitle__77isU.isKR {
    
    width: 70%;
    left: 14.5%;
}
.InputPanel_socialWrapper__Dhaxo .InputPanel_socialButton__Hggw2 .InputPanel_socialTitle__77isU {
    position: absolute;
    width: 60%;
    top: 62px;
    left: 20%;
    font-size: 13px;
    font-weight: 500;
    color: #666;
    text-align: center;
}

.InputPanel_wrapper__RXp1k .social-login-button.facebook-login {
    background-color: #1877f2;
}
.InputPanel_wrapper__RXp1k .social-login-button {
    width: 56px;
    height: 56px;
    margin: 0 auto 8px;
    color: #737373;
    border-radius: 28px;
}
.InputPanel_wrapper__RXp1k .social-login-button.google-login {
    border: 1px solid #e1e2e3;
    background-color: #fff;
}
.InputPanel_wrapper__RXp1k .social-login-button {
    width: 56px;
    height: 56px;
    margin: 0 auto 8px;
    color: #737373;
    border-radius: 28px;
}
.InputPanel_wrapper__RXp1k .social-login-button.apple-login {
    background-color: #000;
}
.InputPanel_wrapper__RXp1k .social-login-button {
    width: 56px;
    height: 56px;
    margin: 0 auto 8px;
    color: #737373;
    border-radius: 28px;
}
.style_wrapper__MbwMv {
    text-align: center;
    font-size: 8pt;
    color: #999;
    margin-top: -10px;
}

@media (max-width: 800px) {
    .advertisement-main {
        display: none;
    }
    .signup-main {
        position:fixed; 
        top:0px;
        left:0px; 
        width: 100%;
        min-width: 480px;
        height:100vh; 
        background:#ffffff; 
        z-index: 1;
        font-family: 'NanumSquare';
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@font-face {
    font-family: 'IBMPlexSansKR-Regular';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/IBMPlexSansKR-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'GmarketSansMedium';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');