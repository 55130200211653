.slide-169-1 {
  left: 0;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'IBMPlexSansKR-Regular';
  font-size: .813rem;
  font-weight: 500;
  line-height: 17px;
  padding-bottom: 30px;
}

.frame-32-instance-1 {
  width: 100%;
  flex-basis: 12%;
}

.rectangle-1 {
  margin: 0 auto;
  color: #262626;
  min-width: 1432px;
  width:100%;
  background: linear-gradient(#f2f2f200 0%, #2e56891a 100%);
}
.width-holder1{
  width:1432px;
  margin: 0 auto;
}
.width-holder2{
  margin: 20px auto 70px;
  width:1432px;
  flex-basis: 70%;
  justify-content: space-between;
  font-size: 2rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
}

.flex-container {
  flex-direction: column;
  flex-basis: 30%;
  justify-content: space-between;
  align-items: stretch;
  margin-top: -60px;
  display: flex;
  margin-left: 10%;
}

.email-validation-and {
  font-weight: 600;
}
.titletext{
  font-size: 1.6rem;
  font-family: 'IBMPlexSansKR-Regular';
}
.plustext {
  font-size: 1rem;
}
.improve-your-deliver {
  margin-top: 0px;
  margin-bottom: 35px;
  font-size: 1rem;
  line-height: 26px;
}
.oneline{
  font-size: 1.3rem;
  line-height: 2.0rem;
  font-family: 'IBMPlexSansKR-Regular';
}
.secondline{
  font-size: 1.1rem;
  line-height: 1.5rem;
  font-family: 'IBMPlexSansKR-Regular';
}
.frame-6-instance-1 {
  width: 100%;
  flex-basis: 16%;
  margin-top: 23px;
}
.flex-container-div{
  min-width: 5%;
}
.flex-container-1 {
  width: 100%;
  flex-direction: column;
  flex-basis: 40%;
  justify-content: space-between;
  align-self: center;
  align-items: stretch;
  margin-right: 140px;
  display: flex;
}

.frame-7-instance-1 {
  width: 100%;
  flex-basis: 22%;
}

.frame-8-instance {
  width: 100%;
  flex-basis: 74%;
}


.group-83 {
  object-fit: cover;
}

.aeogbugbheumf {
  text-align: center;
  margin-left: 3px;
}

.vector-4 {
  width: 28px;
  height: 29px;
  object-fit: cover;
  margin-left: 88px;
}

.api {
  text-align: center;
  margin-left: 3px;
}

.group-79 {
  width: 28px;
  height: 28px;
  object-fit: cover;
  margin-left: 95px;
}

.lvnpiygrakegh {
  text-align: center;
  margin-left: 3px;
}
.stiojaqvggolf{
    text-align: center;
    margin-left: 3px;
}
.certified {
  letter-spacing: 1px;
}

.group-81 {
  object-fit: cover;
}

.flex-container-4 {
  color: #5287c6;
  text-align: center;
  height: 95%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1px;
  margin-left: 117px;
  font-size: 1rem;
  line-height: 16px;
  display: flex;
}

.ldhmlfgdsmvnp {
  z-index: 1;
  margin-right: -4px;
}

.rectangle-12 {
  width: 28px;
  height: 28px;
  object-fit: cover;
  margin-left: -4px;
}

.api-1 {
  text-align: center;
  margin-left: 3px;
}

.button-instance-1 {
  height: 100%;
  flex-basis: 12%;
  margin-top: 1px;
  padding: 1px;
}  
.slide1-container{
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(46, 86, 137, 0.1) 124.06%);
}
.email-container{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  border-radius: 4px;
}
.univName-container{
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;
}
.univName-input{
  width:306px;
  height: 41px;
  border: 1px solid #ccc;
  border-radius: 4px 0px 0px 4px;
  font-family: 'NanumSquare';
  font-size: 10pt;
  font-weight: 600;
  padding-left: 10px;
}
.email-input{
  width:306px;
  height: 41px;
  border: 1px solid #ccc;
  border-radius: 4px 0px 0px 4px;
  font-family: 'NanumSquare';
  font-size: 10pt;
  font-weight: 600;
  padding-left: 10px;
}
.test-button{
align-items: center;
padding: 16px 0px;
gap: 10px;
width: 83px;
height: 45px;
background: #739BE7;
border-radius: 0px 4px 4px 0px;
cursor: pointer;
}
.check{
  margin-top: -7px;
}
.checkbox{
  width: 140px;
  height: 25px;
  left: 332px;
  top: 591px;
  margin-top: 10px;
}
.checkbox:hover + .checkbox-content {
  display: block;
}
.checkbox-content {
  display: none;
  font-size: 10pt;
  line-height: 1.3;
  position: absolute;
  margin-top: 0px;
  margin-left: 9px;
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  border-radius: 0px 30px 30px 30px;
  width : 165px;
  height: 60px;
  font-family: 'NanumSquare';
  font-size: 10pt;
  font-weight: 600;
}
.check-text{
  margin-left: -17px;
}
.py-16 {
    padding-bottom: 4rem;
    padding-top: 4rem;
    margin-left: 10%;
    
}
.md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.container {
    max-width: 1280px;
}
.lg\:items-center {
    align-items: center;
}
.lg\:flex-row {
    flex-direction: row;
}
.lg\:w-1\/2 {
    width: 50%;
}
.lg\:mb-0 {
    margin-bottom: 0;
}
.lg\:mr-20 {
    margin-right: 5rem;
}
.lg\:text-6xl {
    font-size: 2.75rem;
    line-height: 1;
}
.lg\:mb-8 {
    margin-bottom: 2rem;
}
.text-primary-100 {
    --tw-text-opacity: 1;
}
.text-3xl {
    font-size: 1.275rem;
    line-height: 2.25rem;
}
.mt-4 {
    margin-top: 1rem;
}
.lg\:w-1\/3 {
    width:100%;
    margin-left : 0px;
}

.shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0,0,0,.1),0 8px 10px -6px rgba(0,0,0,.1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),0 8px 10px -6px var(--tw-shadow-color);
}
.shadow-md, .shadow-xl {
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
.bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81/var(--tw-bg-opacity));
}
.rounded {
    border-radius: 0.25rem;
}
.items-center {
    align-items: center;
}
.h-64 {
    height: 16rem;
}
.flex {
    display: flex;

}
.info-container{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  width: 543px;
  font-size: .75rem;
  background: #E1E1E1;
  border-radius: 4px;
  font-family: 'GmarketSansMedium';
  animation: slide-up 1.3s ease;
}

code, kbd, pre, samp {
  font-size: 1rem;
}
.code-container {
  width : 100%;
  background: #BBD8FC;
  border-radius: 5px;
  animation: slide-up 1.3s ease;
}
@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.animate {
  animation: slideUp 1.3s ease;
}
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0); 
    opacity: 1;
  }
}

.status-box {
    display: block;
    padding: 0em;
    width: 100%;
    padding: 3px 3px;
    margin-left: 23px;
    line-height: 2.0rem;
    font-family: 'GmarketSansMedium';
    background: #BBD8FC;
    color: #000000;
    background-color: transparent!important;
}
.status-box-green{
  color: #048611;
}
.status-box-red{
  color: #D30000;
}
.status-box-pink{
  color: #D68A8A;
}
.status-box-purple{
    color: #9747FF;
}
.status-box-white{
  color: #fff;
  font-family: 'GmarketSansMedium';
}
.status-box-info{
  margin-left: 160px;
  font-size: 0.7rem;
  color: #7e7e7e;
  font-family: 'GmarketSansMedium';
}
.status-box-punctuation{
  color: #FF8A1E;
}

.info-example{
  margin-top: -17px;
}


.width-holder1{
  min-width: 1432px;
}
.flex-container-2 {
  margin: 0px 110px;
  font-family: 'NanumSquare';
  font-weight: 600;
}
.flex-container-3 {
  display: flex;
  justify-content: space-between;
  min-height: 100px;
  margin: 5px 0px;
  animation: slide-up 1.3s ease;
}


.slide-banner {
  width: 100%;
  height: 35px; /* 배너의 높이 설정 */
  overflow: hidden; /* 내용이 넘칠 경우 가림 */
  position: relative;
  background: #c1d9ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'GmarketSansMedium';
  font-weight: 1000;
  letter-spacing: 2px;
  min-width: 1000px;
}

.slide-content1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  animation: slideRight 30s linear infinite; /* slideRight 애니메이션 적용 */
}
.slide-content2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  animation: slide-right 30s linear infinite; /* slideRight 애니메이션 적용 */
}
.slideicon{
  width: 30px;
  opacity: 0.8;
}
.slide-phrase{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
}
.baro{
  width: 80px;
  margin-left: 20px;
}
@keyframes slideRight {
  0% {
    left: 0; /* 시작 위치 */
  }
  100% {
    left: 100%; /* 이동할 거리 */
  }
}
@keyframes slide-right {
  0% {
    left: -100%; /* 시작 위치 */
  }
  100% {
    left: 0; /* 이동할 거리 */
  }
}


.icontext{
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;  
}
.icontext2{
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-icon{
  margin-right: 20px;
  width: 45px;
}
.info-icon2{
  margin-right: 20px;
  width: 60px;
}
.info-icon3{
  margin-right: 20px;
  width: 75px;
}
.info-icon4{
  margin-right: 20px;
  width: 100px;
}
.p-text{
  font-size: 9pt;
  margin: 6px 0px;
}
h4{
  font-size: 9pt;
  font-weight: 800;
  margin: 0px;
}
h3{
  font-size: 11pt;
  font-weight: 1000;
  margin: 0px;
}
.icontext2{
  color: #000;
  display: flex;
  font-size: 12pt;
}
.footer-icon{
  margin-top: 40px;
  width: 300px;
}