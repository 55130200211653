.slide{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 258px;
}
.slide1{
    width:1432px;
    min-width: 1432px;
}
.container{
    width: 90%;
    margin: 0 auto;
    display : flex  !important;
}

.img-container{
    margin-left: 5%;
    width: 90%;
    min-width: 1000px;
}
.FrameImg{
    width: 96%;
    margin-left: 30px;
}

.frame-container{
    width: 224px;
    min-width: 200px;
    position: block;
    margin-left: 5%;
}
.Frame{
    cursor : pointer;
    width: 100%;
    margin-top: 1px;
}
.reference{
    margin-left: 5%;
    margin-top: 20px;
    width: 30%;
}
.Fmain{
    margin-left: 5%;
    margin-top: 12px;
    width: 20px;
}
.Fexport{
    margin-left: 5%;
    margin-top: 12px;
    width: 47px;
}
.gradle-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    margin: 10px 0;
    background: #EBEFF4;
    border-radius: 10px;
    animation: slide-up 1s ease;
}
.title-container{
    letter-spacing: 3px;
    font-size: 1.2rem;
    line-height: 1rem;
    margin-left: 10px;
    font-family: 'GmarketSansMedium';
}
.code-container1{
    font-size: 0.8rem;
    line-height: 1.2rem;
    margin-left: 10px;
    font-family: 'GmarketSansMedium';
}
.lang-logo{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.blue-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.blue-box {
    background-color: #BBD8FC;
    display: flex;
    flex-direction: column;
    width: 500px;
    border-radius: 10px;
    padding: 20px;
    font-size: 1rem;
    line-height: 1rem;
    font-family: 'GmarketSansMedium';
    animation: slide-up 1s ease;
}
.red-box {
    background-color: #FFC6C6;
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    border-radius: 10px;
    padding: 20px;
    font-size: 1rem;
    line-height: 1rem;
    font-family: 'GmarketSansMedium';
    justify-content: center;
    animation: slide-up 1s ease;
}
.post-box{
    display: flex;
    font-family: 'IBMPlexSansKR-Regular';
    border: 2px solid #e5e5e5;
    border-radius: 10px;
    padding: 20px 30px;
    align-items: center;
}
.post{
    padding: 5px 20px;
    border-radius: 15px;
    background-color: #739BE7;
    color: white;
    font-weight: 600;
}
.post-api{
    margin-left: 20px;
    font-size: 13pt;
    font-weight: 600;
}
.univ-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 15px;
    margin: 15px 0px;
}
.search-input {
    width: 350px;
    margin-left: 580px;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 15px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
}

.search-input::placeholder {
    color: #999;
}

.search-input:focus {
    border-color: #007bff;
}
  
.univ-box {
    background-color: #BBD8FC;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 20px;
    font-size: 1rem;
    line-height: 1rem;
    font-family: 'GmarketSansMedium';
    animation: slide-up 1s ease;
}



.hljs-pink{
    color: #D56545;
    font-weight: bold;
}
.hljs-red{
    color: #D30000;
}
.hljs-grey{
    color: #9EA8B4;
}
h1{
    color: #5287C6;
    font-family: 'IBMPlexSansKR-Regular';
}
h3{
    margin-top: 40px;
    color: #000000;
    font-family: 'IBMPlexSansKR-Regular';
}
@media (max-width: 1500px) {
    .slide{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0px;
        min-width: 1200px;
    }
}
@keyframes slide-up {
    from {
      opacity: 0;
      transform: translateY(20%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }