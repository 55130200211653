a {
    color: rgb(0, 0, 0);
    text-decoration-line: none;
  }
  
  a:hover {
    color: #5287C6;
  }
  
  .MainLeftBar {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    background: #ffffff;
    overflow: hidden;
    z-index: 1;
    font-family: 'NanumSquare';
    border-right: 1px solid #e5e5e5; 
  }
  
  .div3 {
    color: #9d9d9d;
  }
  
  .MainLeftBar ul {
    padding: 0;
    text-align: left;
  }
  
  .MainLeftBar ul ul {
    font-size: 11pt;
    font-weight: bolder;
    width: 210px;
    height: 38px;
    padding-top: 18px;
    padding-left: 48px;
  }
  
  .MainLeftBar ul ul#logo {
    color: #5287C6;
    margin: 0px 0px 15px -5px;
    font-size: 15pt;
    font-weight: 1200;
    height: 10px;
  }
  
  .clicked3 {
    background-color: #5287C6;
    color: #ffffff;
    border-bottom: 4px solid #9bc0ec;
  }
  @media (max-width: 1500px) {
    .MainLeftBar {
      display: none;
    }
  }