.frame-32-instance-1 {
  margin-top: 30px;
  margin-bottom: 40px;
  width: 100%;
}

.frame-32 {
  width: 80%;
  margin: 0 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.letter-frame{
  width: 19%;
  color: #262626;
  background-color: #fff;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1px;
  margin-right: 0px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 26px;
  display: flex;
}
.api-container{
  display: flex;
  margin-left: 0px;
}
.ap-is {
  margin-right: 10px;
  cursor: pointer;
  font-family: 'NanumSquare';
  font-size: 11pt;
  font-weight: 600;
}
.expand-more-1 {
  width: 26px;
  height: 26px;
}

.frame-31 {
  cursor : pointer;
  width: 110px;
  color: #fff;
  background-color: #2e568980;
  border: none;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 10px;
  display: flex;
  font-family: 'NanumSquare';
  font-size: 10pt;
  font-weight: 600;
}
.api-button{
  cursor : pointer;
}
.login{
  cursor : pointer;
}
.dropdown-content {
  display: none;
  position: absolute;
  margin-top: 25px;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 30px;
  z-index: 10;
}
.api-container:hover .dropdown-content {
  display: block;
}
@font-face {
  font-family: 'IBMPlexSansKR-Regular';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/IBMPlexSansKR-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GmarketSansMedium';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');